<template>
  <div>
    <v-lazy v-model="visible">
      <component
        :is="fieldType"
        :field.sync="field"
        :hideHeader="hideHeader"
        :hideBody="hideBody"
        :hideCollapseBtn="hideCollapseBtn"
        :hideDuplicate="sessionField"
        :hideEdit="hideEdit || isSpectatorMode"
        :disabled="disabled || isSpectatorMode"
        :session.sync="session"
        :patient="patient"
        :owner="owner"
        :autosave="autosave && !disabled"
        :clientOnlyPredefined="sessionField || clientOnlyPredefined"
        :name="name"
        @edit="onEdit"
        @history="onHistory"
        @predefined="onPredefined"
        @collapse="onCollapse"
        @append="onAppend"
        @changed:codes="onChangedCodes"
        @copy="onCopy"
      >
      <slot></slot>
      </component>
    </v-lazy>
    <EditFieldDialog
      v-if="showEditDialog"
      :value.sync="showEditDialog"
      :field.sync="currentEditField"
      :name="name"
      :session="session"
      @edited="onEdited"
    >
    </EditFieldDialog>
    <AppointmentHistoryFieldDialog
      v-if="showHistDialog && !sessionField"
      :value.sync="showHistDialog"
      :field="field"
      :session="session"
      :patient="patient"
      :owner="owner"
      :name="name"
    ></AppointmentHistoryFieldDialog>
    <SessionHistoryFieldDialog
      v-if="showHistDialog && sessionField"
      :value.sync="showHistDialog"
      :field="field"
      :session="session"
      :patient="patient"
      :owner="owner"
      :name="name"
    >
    </SessionHistoryFieldDialog>
  </div>
</template>

<script>
import ProxyCRUDMixin from "@/mixins/ProxyCRUDMixin";
import { FIELD_TYPES } from "@/constants/patientcard/fields/types";

export default {
  mixins: [ProxyCRUDMixin],
  props: {
    field: {},
    session: {},
    patient: {},
    owner: {},
    hideHeader: {
      type: Boolean,
      default: false,
    },
    hideCollapseBtn: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideEdit: {
      type: Boolean,
      default: false,
    },
    sessionField: {
      type: Boolean,
      default: false,
    },
    hideBody: {
      type: Boolean,
      default: false,
    },
    autosave: {
      type: Boolean,
      default: false,
    },
    clientOnlyPredefined: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
    forceVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: this.forceVisible,
      showEditDialog: false,
      showHistDialog: false,
      currentEditField: null,
    };
  },
  computed: {
    fieldType() {
      switch (this.field.field_type) {
        case FIELD_TYPES.ICD9_CODES_FIELD:
        case FIELD_TYPES.ICD10_CODES_FIELD:
        case FIELD_TYPES.ICF_CODES_FIELD:
          return "MedicalCodesDelegate";
        case FIELD_TYPES.DRAWINGS_FIELD:
          return "DrawingsFieldDelegate";
        case FIELD_TYPES.DATE_FIELD:
          return "DateFieldDelegate";
        case FIELD_TYPES.POP_Q_FIELD:
          return "PopQFieldDelegate";
        case FIELD_TYPES.PER_OXF_SCALE_FIELD:
          return "PerfOxfFieldDelegate";
        case FIELD_TYPES.DRA_TABLE_FIELD:
          return "DRAFieldDelegate";
        case FIELD_TYPES.DRAWINGS_DRA_FIELD:
        case FIELD_TYPES.DRAWINGS_SCARS_FIELD:
        case FIELD_TYPES.DRAWINGS_VIS_CROTCH_FIELD:
        case FIELD_TYPES.DRAWINGS_CROTCH_FIELD:
        case FIELD_TYPES.DRAWINGS_CHILD_FIELD:
        case FIELD_TYPES.DRAWINGS_INFANT_BACK_FIELD:
        case FIELD_TYPES.DRAWINGS_INFANT_FRONT_FIELD:
          return "SingleDrawingFieldDelegate";
        case FIELD_TYPES.PREGNANCY_STATE_FIELD:
          return "PregnancyStatusFieldDelegate";
        case FIELD_TYPES.PREGNANCY_LIST_FIELD:
          return "PregnancyListFieldDelegate";
        case FIELD_TYPES.CHILD_OVERALL_ASSESSMENT_FIELD:
          return "ChildOverallAssessmentFieldDelegate";
        case FIELD_TYPES.PREGNANCY_INTERVIEW_FIELD:
          return "PregnancyInterviewFieldDelegate";
        case FIELD_TYPES.CHILD_ACTIVITY_ASSESSMENT_FIELD:
          return "ChildActivityAssessmentFieldDelegate";
        case FIELD_TYPES.SWITCH_FIELD:
          return "SwitchFieldDelegate";
        case FIELD_TYPES.TEXT_FIELD:
        default:
          return "TextFieldDelegate";
      }
    },
    isSpectatorMode() {
      return !!this.owner;
    },
  },
  components: {
    TextFieldDelegate: () =>
      import("@/components/delegates/patientcard/fields/TextFieldDelegate"),
    MedicalCodesDelegate: () =>
      import("@/components/delegates/patientcard/fields/MedicalCodesDelegate"),
    DrawingsFieldDelegate: () =>
      import("@/components/delegates/patientcard/fields/DrawingsFieldDelegate"),
    DateFieldDelegate: () =>
      import("@/components/delegates/patientcard/fields/DateFieldDelegate"),
    PopQFieldDelegate: () =>
      import("@/components/delegates/patientcard/fields/PopQFieldDelegate"),
    PerfOxfFieldDelegate: () =>
      import("@/components/delegates/patientcard/fields/PerfOxfFieldDelegate"),
    DRAFieldDelegate: () =>
      import("@/components/delegates/patientcard/fields/DRAFieldDelegate"),
    SingleDrawingFieldDelegate: () =>
      import(
        "@/components/delegates/patientcard/fields/SingleDrawingFieldDelegate"
      ),
    PregnancyStatusFieldDelegate: () =>
      import(
        "@/components/delegates/patientcard/fields/PregnancyStatusFieldDelegate"
      ),
    PregnancyListFieldDelegate: () =>
      import(
        "@/components/delegates/patientcard/fields/PregnancyListFieldDelegate"
      ),
    EditFieldDialog: () =>
      import("@/components/popups/patientcard/EditFieldDialog"),
    AppointmentHistoryFieldDialog: () =>
      import("@/components/popups/patientcard/AppointmentHistoryFieldDialog"),
    SessionHistoryFieldDialog: () =>
      import("@/components/popups/patientcard/SessionHistoryFieldDialog"),
    ChildOverallAssessmentFieldDelegate: () =>
      import(
        "@/components/delegates/patientcard/fields/ChildOverallAssessmentFieldDelegate"
      ),
    PregnancyInterviewFieldDelegate: () =>
      import(
        "@/components/delegates/patientcard/fields/PregnancyInterviewFieldDelegate"
      ),
    SwitchFieldDelegate: () =>
      import("@/components/delegates/patientcard/fields/SwitchFieldDelegate"),
    ChildActivityAssessmentFieldDelegate: () =>
      import(
        "@/components/delegates/patientcard/fields/ChildActivityAssessmentFieldDelegate"
      ),
  },
  methods: {
    onEdit() {
      this.currentEditField = JSON.parse(JSON.stringify(this.field));
      this.showEditDialog = true;
    },
    onEdited(editedField) {
      this.$set(this.field, "data", editedField.data);
      this.$set(this.field, "modified", editedField.modified);
      this.$set(this.field, "reason", editedField.reason);
      this.handleSuccess("Wprowadzone zmiany zostały zapisane");
    },
    onHistory() {
      this.showHistDialog = true;
    },
    onPredefined(data) {
      this.$emit("predefined", { field: this.field, data });
    },
    onCollapse() {
      this.$emit("collapse");
    },
    onAppend(data) {
      this.$emit("append", { field: this.field, data });
    },
    onChangedCodes() {
      this.$emit("changed:codes");
    },
    onCopy() {
      this.$emit("copy");
    }
  },
};
</script>